<template>
  <div class="tmblr" :class="{ 'tmblr-active': active, 'with-sale-price': salePrice }">
    <div
      class="btn btn-tmblr"
      :class="active ? 'btn-tmblr-r' : 'btn-tmblr-l'"
      @click="changeState"
    >
      <!--<Close v-show="!active" class="img img_close" />
      <Check v-show="active" class="img img_check" />-->
    </div>
    <div class="txt d-flex" v-if="withStar">
      <Star class="img" />
      <span>
        {{ text }}
      </span>
    </div>
    <div class="txt" v-else>
      {{ text }}
    </div>
    <div v-if="price || salePrice" class="price--wrapper">
      <div v-if="+salePrice > 0" class="sale--price" :class="{'sale': +salePrice < +price}">{{ parseInt(salePrice) | numberFormat }}</div>
      <div v-if="+price > 0" class="price">{{ parseInt(price) | numberFormat }}</div>
    </div>
  </div>
</template>

<script>
import Close from '@/components/img/Close.vue'
import Check from '@/components/img/Check.vue'
import Star from '@/components/img/Star.vue'

export default {
  components: {
    Close,
    Check,
    Star
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    price: {
      type: Number,
      default: 0,
    },
    salePrice: {
      type: Number,
      default: 0,
    },
    text: String,
    withStar: Boolean,
    default: false,
  },
  methods: {
    changeState() {
      this.$emit('changeState')
    }
  }
}
</script>

<style lang="scss" scoped>
.tmblr {
  display: flex;
  align-items: center;
  &.with-sale-price{
    align-items: flex-start;
  }

  .txt {
    color: #09090B;
    margin-right: 12px;
    &.d-flex{
      display: flex;
      align-items: center;
    }
  }

  .price--wrapper{
    margin-left: auto;
    color: #ababb2;
    flex-shrink: 0;
    display: flex;
    flex-direction: column-reverse;
  }

  .sale--price + .price{
    text-decoration: line-through;
  }
  .sale--price.sale{
    color: #EF4C4C;
  }

  .btn {
    .img_close{
      fill: #000;
    }
    &-tmblr {
      position: relative;
      width: 2rem;
      height: .5rem;
      border-radius: 2rem;
      margin: auto .75rem auto .25rem;
      background: #f1f1f1;
      flex: 1 0 2rem;
      max-width: 2rem;

      &-l::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-25%, -50%);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: #ababb2;
      }

      &-r::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(25%, -50%);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: #ababb2;
      }

      ::v-deep svg.img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;

        &_close {
          left: 0;
          width: 8px;
          height: 8px;

          path {
            fill:#a1a1a1;
          }
        }

        &_check {
          right: -1px;

          path {
            fill: #ffffff;
          }
        }
      }
    }
  }
  svg.img {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: .5rem;
  }
  &.with-sale-price{
    .btn{
      &-tmblr{
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }
  &-active {
    .txt, .price {
      color: #1763fb;
    }
    .sale--price + .price{
      color: #09090B;
    }
    .btn-tmblr-r::before {
      background-color:  #1763fb;
    }
  }
}
</style>